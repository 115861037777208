<template>
	<form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-grid">
		<Toast />
		<ConfirmDialog></ConfirmDialog>
		
		<div class="grid p-fluid stickBar">
			<div class="col-12">
				<div class="card card-w-title" id="stickBar">
					<Menubar :model="nestedMenuitems">
						<template #end>
							<div class="flex justify-content-end flex-wrap card-container green-container">
								<div class="flex align-items-center justify-content-center font-bold text-gray-500 text-xl m-2">
									{{ mainData.sozlesmeAdi }}
								</div>
								<div class="flex align-items-center justify-content-center m-2">
									<Button class="p-button-rounded p-button-danger mr-0 mb-0" v-if="$router.options.history.state.back != null" icon="pi pi-times" @click="$router.go(-1)"/>
								</div>
							</div>
						</template>
					</Menubar>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<EntityHeader label="SÖZLEŞMELER" entityName="bm_sozlesmeler"></EntityHeader>
					<div class="grid">
						
						
						<div class="col-4">
							<div class="field p-fluid">
								<label for="adi">Sözleşme Adı <span style="color:red">*</span></label>
								<InputText id="adi" type="text" v-model="mainData.sozlesmeAdi" :disabled="isDisabled" />
								<span v-if="v$.mainData.sozlesmeAdi.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.sozlesmeAdi.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						
						<div class="col-4">
							<div class="field p-fluid">
								<EntityLookup id="firma" v-model="mainData.firmaName" ref="entity_firma"
									label="Firma" entityName="account" nameField="name" :state="true" :required="true" :disabled="isDisabled"
									@itemSelected="firmaSelected = $event" @itemCleared="firmaSelected = null">
								</EntityLookup>
							</div>
						</div>

						<div class="col-4">
							<div class="field p-fluid">
								<EntityLookup id="sahibid" v-model="mainData.sahibidName" ref="entity_sahibid"
									label="Form Makine Sorumlusu" entityName="bm_teknikkullanicilar" nameField="bm_name" :state="true" :disabled="isDisabled"
									@itemSelected="sahibidSelected = $event" @itemCleared="sahibidSelected = null">
								</EntityLookup>
							</div>
						</div>

						<div class="col-3">
							<div class="field p-fluid">
								<label for="durum">Durum</label>
								<Dropdown id="durum" v-model="mainData.durum" :options="SM_durum" optionLabel="Value" optionValue="AttributeValue" placeholder="Durum" :showClear="true" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="paraBirimi" v-model="mainData.paraBirimiName" ref="entity_paraBirimi"
									label="Para Birimi" entityName="transactioncurrency" nameField="currencyname" :state="true" :disabled="isDisabled"
									@itemSelected="paraBirimiSelected = $event" @itemCleared="paraBirimiSelected = null">
								</EntityLookup>
							</div>
						</div>
						<div class="col-6">
							<div class="field p-fluid">
								<EntityLookup id="ilgiliKisi" v-model="mainData.ilgiliKisiName" ref="entity_ilgiliKisi"
									label="İlgili Kişi" entityName="contact" nameField="fullname" :state="true" :disabled="isDisabled"
									parentFieldName="parentcustomerid" :parentId="firmaSelected"
									@itemSelected="ilgiliKisiSelected = $event" @itemCleared="ilgiliKisiSelected = null">
								</EntityLookup>
							</div>
						</div>
						
						
						
					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<h5>TARİHLER</h5>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<label for="teklifTarihi">Teklif Tarihi</label>
								<Calendar showButtonBar id="teklifTarihi" v-model="mainData.teklifTarihi" autocomplete="off" :showIcon="true" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="teklifSozlesmeSuresi">Teklif Sözleşme Süresi</label>
								<Dropdown id="teklifSozlesmeSuresi" v-model="mainData.teklifSozlesmeSuresi" :options="SM_teklifSozlesmeSuresi" optionLabel="Value" optionValue="AttributeValue" placeholder="Teklif Sözleşme Süresi" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-6">

						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="sozlesmeBaslangicTarihi">Sözleşme Başlangıç Tarihi</label>
								<Calendar showButtonBar id="sozlesmeBaslangicTarihi" v-model="mainData.sozlesmeBaslangicTarihi" autocomplete="off" :showIcon="true" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="sozlesmeSuresi">Sözleşme Süresi</label>
								<Dropdown id="sozlesmeSuresi" v-model="mainData.sozlesmeSuresi" :options="SM_sozlesmeSuresi" optionLabel="Value" optionValue="AttributeValue" placeholder="Sözleşme Süresi" :showClear="true" @change="changeSozlesmeSuresi" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="sozlesmeBitisTarihi">Sözleşme Bitiş Tarihi</label>
								<Calendar showButtonBar id="sozlesmeBitisTarihi" v-model="mainData.sozlesmeBitisTarihi" autocomplete="off" :showIcon="true" :disabled="isDisabled" />
							</div>
						</div>

					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<h5>FİYATLANDIRMA</h5>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<label for="servisSaatindirimi">Servis Saat İndirimi</label>
								<InputNumber id="servisSaatindirimi" v-model="mainData.servisSaatindirimi" mode="decimal" locale="tr-TR" :minFractionDigits="0" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="toplamTutar">Toplam Tutar</label>
								<InputNumber id="calisanSayisi" v-model="mainData.toplamTutar" mode="decimal" locale="tr-TR" :minFractionDigits="0" :disabled="isDisabled"  />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="indirimTutar">İndirim Tutar</label>
								<InputNumber id="indirimTutar" v-model="mainData.indirimTutar" mode="decimal" locale="tr-TR" :minFractionDigits="0" :max="mainData.toplamTutar" :disabled="isDisabled" @input="changeIndirimTutari" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="indirimOran">İndirim Oranı %</label>
								<InputNumber id="indirimOran" v-model="mainData.indirimOran" mode="decimal" locale="tr-TR" :minFractionDigits="0" max="100" :disabled="isDisabled" @input="changeIndirimOrani" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="faturaEdildimi">Fatura Edildi mi?</label>
								<Dropdown id="faturaEdildimi" v-model="mainData.faturaEdildimi" :options="SM_faturaEdildimi" optionLabel="Value" optionValue="AttributeValue" placeholder="Fatura Edildi mi?" :showClear="true" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="faturaTarihi">Fatura Tarihi</label>
								<Calendar showButtonBar id="faturaTarihi" v-model="mainData.faturaTarihi" autocomplete="off" :showIcon="true" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="ucretsizServisSaati">Ücretsiz Servis Saati</label>
								<InputNumber id="ucretsizServisSaati" v-model="mainData.ucretsizServisSaati" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="genelToplam">Genel Toplam</label>
								<InputNumber id="genelToplam" v-model="mainData.genelToplam" mode="decimal" locale="tr-TR" :disabled="true" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<h5>OTOMATİK TEZGAH OLUŞTUR</h5>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<label for="faturaEdildimi">Sözleşme Satırlarını Oluştur</label>
								<Dropdown id="faturaEdildimi" v-model="mainData.sozlesmeSatirlariniOlustur" :options="SM_sozlesmeSatirlariniOlustur" optionLabel="Value" optionValue="AttributeValue" placeholder="Sözleşme Satırlarını Oluştur" :showClear="true" :disabled="isDisabled" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<h5>SÖZLEŞME SATIRLARI</h5>
					<Button icon="pi pi-plus" class="p-button-secondary" label="Sözleşme Satırı Ekle" @click="SozlesmeSatiriEkle()" :disabled="isDisabled" />

					<CrmDataTable :key="CrmDataTable_bm_sozlesmesatiri_key" baseEntityName="bm_sozlesmesatiri" :options="CrmDataTable_bm_sozlesmesatiri_options" :rows="50"
								:isHeaderVisible="true" :doubleClickOpenEntity="true" @onRowDelete="onRowDelete" />
				</div>
			</div>

			<div class="col-12">
				<div class="field p-fluid">
					<Annotation objectTypeCode="10019" logicalName="bm_sozlesmeler" :objectId="entityId" ></Annotation>
				</div>
			</div>
		</div>

		<Dialog v-model:visible="displayIndir" :breakpoints="{'960px': '90vw', '640px': '90vw'}" :style="{width: '40vw'}" header="Teklif Indir" :modal="true" :closable="true" :showHeader="true" :draggable="true">
            <br>
            <div class="p-4">
                <div class="grid">
                    <div class="col">
                        <h3>{{ seciliRaporAdi }}</h3>
                        <hr/>
                        <Button label="PDF" class="p-button-rounded p-button-danger" icon="pi pi-file-pdf" @click="teklifIndir('pdf')" />
                        <Button label="EXCEL" class="p-button-rounded p-button-success" icon="pi pi-file-excel" style="margin-left: .5em" @click="teklifIndir('xls')"/>
						<Button label="WORD" class="p-button-rounded p-button-success" icon="pi pi-file-word" style="margin-left: .5em" @click="teklifIndir('doc')"/>
                        <Button label="GÖSTER" class="p-button-rounded p-button-info" icon="pi pi-eye" style="margin-left: .5em" @click="teklifGoster()"/>
                    </div>
                </div>

                <Button v-if="false" label="Durumu Müşteriye Gönderildi olarak DEĞİŞTİR" class="mt-3 p-button-secondary" icon="pi pi-save" @click="Islem_MusteriyeTeklifGonderildiYap" />
            </div>
        </Dialog>
	</form>
</template>

<script>
import CrmService from "../service/CrmService";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";

export default {
	setup: () => ({ v$: useVuelidate() }),
	data() {
		return {
			crmService: null,
			submitted: false,
			OBJECT_TYPE_CODE: 10019,
			mainData: {},

			displayIndir: false,
            seciliRaporAdi: null,
            seciliRaporName: null,
            seciliRaporParamName: null,

			nestedMenuitems: [
				{
					label:'Kaydet',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(false); },
					visible: () => !this.isDisabled
				},
				{
					label:'Kaydet & Kapat',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(true); },
					visible: () => !this.isDisabled
				},
				{
                    label: "Sil",
                    icon: "pi pi-fw pi-trash",
                    command: () => { this.OnDelete(); },
					visible: () => this.isDeleted
                },
				{
                    label: "Sözleşme Teklif Yazdır",
                    icon: "pi pi-fw pi-print",
                    items: [
                        {
                            label: "Mazak Teklifi",
                            command: () => { this.GotoReport("BakimSozlesmesiMazak", "BakimSozlesmesi_mazak", "contractId"); },
                            icon: "pi pi-fw pi-print"
                        },
						{
                            label: "Studer Teklifi",
                            command: () => { this.GotoReport("BakimSozlesmesiStuder", "BakimSozlesmesi_studer", "contractId"); },
                            icon: "pi pi-fw pi-print"
                        },
						{
                            label: "Komatech Teklifi",
                            command: () => { this.GotoReport("BakimSozlesmesiKomatech", "BakimSozlesmesi_komatech", "contractId"); },
                            icon: "pi pi-fw pi-print"
                        },
						{
                            label: "Nomura Teklifi",
                            command: () => { this.GotoReport("BakimSozlesmesiNomura", "BakimSozlesmesi_nomura", "contractId"); },
                            icon: "pi pi-fw pi-print"
                        },
                    ]
                },
			],
			CrmDataTable_bm_sozlesmesatiri_key: 0,
			CrmDataTable_bm_sozlesmesatiri_options: {
				searchAttributes: ["bm_name"],
				filterParent: [
					{ 'name': 'bm_sozlemesatirlarid', 'filterOperator': 'eq', 'value': this.entityId },
				],
				forceSavedQueryId: 'F04DFCC0-5CDD-ED11-AFA0-000C2920F782',
				views: [
					{ 'viewName': 'Etkin Sözleşme Satırları', 'savedQueryId': 'F04DFCC0-5CDD-ED11-AFA0-000C2920F782' },
				],
				preventSystemViews: true,
				preventUserViews: true
			},
		}
	},
	async created() {
		this.crmService = new CrmService();
		console.log('created');
		window.addEventListener('scroll', this.handleScroll);

		window.addEventListener("message", (event)=>{
			var origin = event.origin;
			if (window.origin == origin && event.isTrusted && event.data.tag != undefined) {
				if (event.data.tag == "sozlesmeler_reload") {
					this.OnLoad();
				}
			}
		});

		this.OnLoad();
    },
	computed: {
		entityId() {
			debugger;
			return this.$route.params.id;
		},
		profileData(){
			return this.$store.getters.getProfile;
		},
		isDisabled: function() {
			if (this.profileData) {
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == 'Sözleşmeler');
					if (filtered.length > 0) {
						return filtered[0].isWrite == false;
					}
				}
			}

			return true;
		},
		isDeleted: function() {
			if (this.profileData) {
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == 'Sözleşmeler');
					if (filtered.length > 0) {
						return filtered[0].isDelete == true;
					}
				}
			}

			return false;
		},
		firmaSelected: {
			get: function () {
				debugger;
				if (this.mainData["firmaId"]) {
					return { "Value": this.mainData["firmaId"], "Name": this.mainData["firmaName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				debugger;
				console.log(newValue);

				if (newValue == null) {
					this.mainData["firmaId"] = null;
					this.mainData["firmaName"] = null;
				}
				else {
					this.mainData["firmaId"] = newValue.Value;
					this.mainData["firmaName"] = newValue.Name;
				}
			}
		},
		ilgiliKisiSelected: {
			get: function () {
				if (this.mainData["ilgiliKisiId"]) {
					return { "Value": this.mainData["ilgiliKisiId"], "Name": this.mainData["ilgiliKisiName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["ilgiliKisiId"] = null;
					this.mainData["ilgiliKisiName"] = null;
				}
				else {
					this.mainData["ilgiliKisiId"] = newValue.Value;
					this.mainData["ilgiliKisiName"] = newValue.Name;
				}
			}
		},
		paraBirimiSelected: {
			get: function () {
				if (this.mainData["paraBirimiId"]) {
					return { "Value": this.mainData["paraBirimiId"], "Name": this.mainData["paraBirimiName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["paraBirimiId"] = null;
					this.mainData["paraBirimiName"] = null;
				}
				else {
					this.mainData["paraBirimiId"] = newValue.Value;
					this.mainData["paraBirimiName"] = newValue.Name;
				}
			}
		},
		sahibidSelected: {
			get: function () {
				if (this.mainData["sahibidId"]) {
					return { "Value": this.mainData["sahibidId"], "Name": this.mainData["sahibidName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["sahibidId"] = null;
					this.mainData["sahibidName"] = null;
				}
				else {
					this.mainData["sahibidId"] = newValue.Value;
					this.mainData["sahibidName"] = newValue.Name;
				}
			}
		},
		SM_durum: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_durum');
		},
		SM_sozlesmeSuresi: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_sozlemesuresi');
		},
		SM_teklifSozlesmeSuresi: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_teklifsozlesmesuresi');
		},
		SM_faturaEdildimi: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_faturaedildimi');
		},
		SM_sozlesmeSatirlariniOlustur: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_sozlesmesatirlariniolustur', 'bool');
		},
	},
	methods: {
		async OnLoad(){

			let loader = this.$loading.show({
				container: this.$refs.smsContainer
			});

			debugger;
			if (this.entityId != null) {
				try {
					debugger;
					let data = await this.crmService.getSozlesmeById(this.entityId);
					if (data.yetkiVarmi == false) {
						this.$router.replace({ name: 'accessdenied' });
					}
					
					if (data.jsonData.length > 0) {
						this.mainData = data.jsonData[0];
						this.mainData["entityId"] = this.entityId;

						if (this.mainData["firmaId"]) {
							this.$refs.entity_firma.setDefaultData({ "Value": this.mainData["firmaId"], "Name": this.mainData["firmaName"] });
						}

						if (this.mainData["ilgiliKisiId"]) {
							this.$refs.entity_ilgiliKisi.setDefaultData({ "Value": this.mainData["ilgiliKisiId"], "Name": this.mainData["ilgiliKisiName"] });
						}

						if (this.mainData["sahibidId"]) {
							this.$refs.entity_sahibid.setDefaultData({ "Value": this.mainData["sahibidId"], "Name": this.mainData["sahibidName"] });
						}

						if (this.mainData["paraBirimiId"]) {
							this.$refs.entity_paraBirimi.setDefaultData({ "Value": this.mainData["paraBirimiId"], "Name": this.mainData["paraBirimiName"] });
						}

						if (this.mainData["teklifTarihi"]) {
							this.mainData["teklifTarihi"] = new Date(this.mainData.teklifTarihi);
						}

						if (this.mainData["sozlesmeBaslangicTarihi"]) {
							this.mainData["sozlesmeBaslangicTarihi"] = new Date(this.mainData.sozlesmeBaslangicTarihi);
						}

						if (this.mainData["sozlesmeBitisTarihi"]) {
							this.mainData["sozlesmeBitisTarihi"] = new Date(this.mainData.sozlesmeBitisTarihi);
						}

						if (this.mainData["faturaTarihi"]) {
							this.mainData["faturaTarihi"] = new Date(this.mainData.faturaTarihi);
						}

						this.CrmDataTable_bm_sozlesmesatiri_options.filterParent[0].value = this.entityId;
						this.CrmDataTable_bm_sozlesmesatiri_key++;
					}
				} catch (error) {
					console.log(error);
					//this.$router.push('/');
				}
				finally {
					loader.hide();
				}
			}
			else {
				loader.hide();
			}

		},
		async OnSave(kapat) {
			debugger;

			this.submitted = true;
			this.v$.$touch();

			if (this.v$.$invalid) {
				this.v$.$errors.forEach(element => {
					this.$toast.add({ severity: 'error', summary: 'Eksik alanlar var.', detail: element.$message, life: 3500 });
				});
			} else {
				try {
					const response = await this.crmService.getSozlesmeUpdate(this.mainData);
					if (response) {
						if (response.hata == true) {
							this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
						}else {
							this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
							if (window.opener) {
								window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
							}
							
							if (kapat) {
								setTimeout(() => {
									window.close();
								}, 1000);
							}
							else {
								this.OnLoad();
							}
						}
					}
				} catch (error) {
					this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
				}
			}
		},
		async OnDelete(){
            this.deleteItem('bm_sozlesmeler', this.entityId);
        },
		SozlesmeSatiriEkle(){
			let features = 'directories=no,menubar=no,status=no,titlebar=no,toolbar=no';

			let routeData = this.$router.resolve({ name: 'bm_sozlesmesatirinew', params: { sozlesmeId: this.entityId, sozlesmeName: this.mainData.sozlesmeAdi } });
			window.open(routeData.href, '_blank', features);
		},
		changeSozlesmeSuresi(event){
			debugger;
			console.log(event);

			if (event.value) {
				let sozlesmeBaslangicTarihi = this.mainData.sozlesmeBaslangicTarihi;
				if (sozlesmeBaslangicTarihi == null) {
					this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: "Lütfen önce Sözleşme Başlangıç Tarihini doldurunuz!", life: 3500});
					this.mainData.sozlesmeSuresi = null;
				}
				else {
					var year = this.mainData.sozlesmeBaslangicTarihi.getFullYear();
					var month = this.mainData.sozlesmeBaslangicTarihi.getMonth();
					var day = this.mainData.sozlesmeBaslangicTarihi.getDate();
					var bitisTarihi = new Date(year + event.value, month, day);
					this.mainData.sozlesmeBitisTarihi = bitisTarihi;
				}
			}
		},
		changeIndirimTutari(event){
			if (event.value > this.mainData.toplamTutar) {
				event.value = this.mainData.toplamTutar
			}

			this.mainData.genelToplam = this.mainData.toplamTutar - event.value;
			this.mainData.indirimOran = null;
		},
		changeIndirimOrani(event){
			if (event.value > 100) {
				event.value = 100;
			}

			this.mainData.genelToplam = this.mainData.toplamTutar  - (this.mainData.toplamTutar * (event.value / 100));
			this.mainData.indirimTutar = null;
		},
		onRowSelectSozlesmeSatiri(event) {
			//this.$router.push({ name: 'quote', params: { id: event.data.ID } });
			let features = 'directories=no,menubar=no,status=no,titlebar=no,toolbar=no';

			let routeData = this.$router.resolve({name: 'bm_sozlesmesatiri', params: { id: event.data.ID }});
			window.open(routeData.href, '_blank', features);
        },
		onRowDelete(event) {
			debugger;
			console.log(event);
			this.OnLoad();
		},
		GotoReport(Title, Name, ParamName) {
            this.displayIndir = true;
            this.seciliRaporAdi = Title;
            this.seciliRaporName = Name;
            this.seciliRaporParamName = ParamName;
        },
        teklifGoster(){
            const reportUrl = process.env.VUE_APP_WEBAPP_URL + "/SSRS_ReportViewer.aspx?name=" + this.seciliRaporName + "&" + this.seciliRaporParamName + "=" + this.entityId;
            window.open(reportUrl, "qqqq", "_blank");
        },
        teklifIndir(fileType){
            let loader = this.$loading.show({
                container: this.$refs.mainContainer
            });

            console.log(fileType);

            debugger;
            this.crmService.GetSsrsReport(this.seciliRaporName, this.seciliRaporParamName + '=' + this.entityId, fileType).then(responseSSRS => {
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";

                const url = window.URL.createObjectURL(responseSSRS.data);
                a.href = url;
                a.download = this.seciliRaporAdi + "." + fileType;
                a.click();

                a.remove();
                window.URL.revokeObjectURL(url);
                loader.hide();

                this.displayIndir = false;
            })
            .catch(error => {
                loader.hide();
                debugger;
                console.log(error);
            });
        },
		handleScroll() {
			const el = document.getElementById('stickBar');

			if (window.scrollY > 50) {
				el.style.boxShadow = '0px 2px 1px rgba(0, 0, 0, 0.09), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09), 0px 32px 16px rgba(0, 0, 0, 0.09)';
			}
			else {
				el.style.boxShadow = '';
			}
		},
	},
	watch: {
		profileData(val) {
			if (val) {
				if (val.moduller) {
					const filtered = val.moduller.filter(x => x.name == 'Sözleşmeler');
					if (filtered.length == 0) {
						this.$router.replace({ name: 'accessdenied' });
					}
				}
			}
		}
	},
	validations() {
		return {
			mainData: {
				sozlesmeAdi: {
					required : helpers.withMessage('Adı alanı dolu olmalıdır!', required),
				},
			},
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
